/* Nestable list */
.dd { position: relative; display: block; margin: 0; padding: 0; list-style: none; float: left;width: 100%;}

.dd-list{ display: block; position: relative; margin: 0; padding: 0; list-style: none; width: auto; float: none;}
.dd-list .dd-list { padding-left: 30px; }
.dd-collapsed .dd-list { display: none; }

.dd-list li{float: none;width: auto;}

.dd-item,.dd-empty,.dd-placeholder{ display: block; position: relative; margin: 0; padding: 0; min-height: 20px; font-weight: 400; line-height: 20px; }

.dd-handle { display: block; line-height: 28px; margin: 5px 0; padding: 5px 20px; text-decoration: none; border: 1px solid @app-desing-border-color;
    background: @app-desing-base-element-bg; .border-radius(5px);box-sizing: border-box; -moz-box-sizing: border-box; cursor: pointer; .transition(background 100ms linear);
}
.dd-handle:hover { color: @app-layout-base-font-color-heading; background: @app-desing-base-element-bg-hover; }

.dd-item > button { color: @app-layout-base-font-color-secondary;display: block; position: relative; cursor: pointer; float: left; width: 30px; height: 30px; margin: 5px 0; padding: 0; text-indent: 100%; white-space: nowrap; overflow: hidden; border: 0; background: transparent; font-size: 14px; line-height: 1; text-align: center;}
.dd-item > button:before { content: "\f107"; font-family: "FontAwesome"; display: block; position: absolute; width: 100%; text-align: center; text-indent: 0; }
.dd-item > button[data-action="collapse"]:before { content: "\f106"; }

.dd-placeholder,
.dd-empty { margin: 5px 0; padding: 0; min-height: 30px; background: #f2fbff; border: 1px dashed @app-desing-border-color; box-sizing: border-box; -moz-box-sizing: border-box; }
.dd-empty { border: 1px dashed #bbb; min-height: 100px; background-color: #e5e5e5;}

.dd-dragel { position: absolute; pointer-events: none; z-index: 9999; }
.dd-dragel > .dd-item .dd-handle { margin-top: 0; }
.dd-dragel .dd-handle {
    -webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
            box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
}

.dd3-content {display: block; height: 30px; margin: 5px 0; padding: 5px 10px 5px 40px; text-decoration: none; border: 1px solid #D5D5D5;
              background: #f5f5f5;box-sizing: border-box; -moz-box-sizing: border-box; .border-radius(5px);}
.dd3-content:hover { color: @app-layout-base-font-color-heading; background: #fff; }

.dd-dragel > .dd3-item > .dd3-content { margin: 0; }

.dd3-item > button { margin-left: 30px; }

.dd3-handle {position: absolute; margin: 0; left: 0; top: 0; cursor: pointer; width: 30px; text-indent: 100%; white-space: nowrap; overflow: hidden;
             border: 1px solid #D5D5D5;background: #F0F0F0;.border-radiuses(5px 0px 0px 5px);}
.dd3-handle:before{content: '≡'; display: block; position: absolute; left: 0; top: 3px; width: 100%; text-align: center; text-indent: 0; color: #333; font-size: 16px; font-weight: normal;}
.dd3-handle:hover{background: #E5E5E5;}
/* end nestable list */