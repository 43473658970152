.btn-select {
    display: block !important;
    .dropdown-menu {
        max-height: 200px;
        overflow-y: scroll;
        li a {
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .bs-searchbox {
            margin: 0 !important;
        }
    }
    .dropdown {
        float: none;
    }
}

.datepicker-ctrl {
    height: 34px !important;
}

.datepicker-input {
    width: 100% !important;
}

.datepicker-ctrl {
    p {
        float: none !important;
    }
}

.datepicker {
    width: 100% !important;
}

.remove-block {
    position: absolute;
    right: 15px;
    cursor: pointer;
    z-index: 400;
    &:hover {
        text-decoration: none;
    }
}

.model-typeahead {
    &.selected {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    &.dropdown {
        float: none;
    }
}

#materials, #prints, #prices, #contract {
    .block {
        padding-bottom: 0;
    }
}

.datepicker-nextBtn {
    &:before {
        content: '>';
    }
}

.datepicker-preBtn {
    &:before {
        content: '<';
    }

}

.v-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control.dropdown-toggle {
    height: 40px !important;
}

.product-image {
    display: inline-block;
    background-color: #f5f5f5;
    text-align: center;
    height: auto;
    transition: all .5s;
    img {
        max-width: 100%;
        width: auto;
        margin: 0 auto;
    }
}

.product-image-remove {
    display: block;
    width: 100px;
    margin-top: 5px;
}

.remove-image-wrapper {
    margin-bottom: 10px;
}

.absolutepanel {
    position: absolute;
    right: 0;
    top: 66px;
    z-index: 200;
    .shadow(0px, 3px, 4px)
}

.table-condensed {
    .table-condensed {
        margin-bottom: 0 !important;
    }
    tr td, tr th {
        line-height: 1 !important;
        padding: 5px 8px !important;
    }
}

#svg-wrap path {
    stroke: #fff;
    fill-rule: evenodd;
}

#svg-wrap {
    text-align: center;
}

.flex-table {
    display: flex;
    flex-wrap: wrap;
    //margin-left: -10px;
    margin-right: -10px;
    .fa {
        font-size: 16px !important;
    }
    .table-group {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #ddd;
        &:last-child {
            //border-bottom: none;
        }
        height: auto;
        &.collapsed {
            > .table-group {
                height: 0;
                display: none;
                border-bottom: none;
            }
        }
    }
}

.product-link {
    position: relative;
    display: inline-block;
    .panel {
        width: 350px;
        .shadow(0px, 3px, 4px);
    }

    .panel-container {
        bottom: 0;
        display: none;
        position: absolute;
        padding-bottom: 14px;
    }

    &.showpanel .panel-container {
        display: block;
    }

    .panel-body {
        display: flex;
    }

    .panel-left {
        margin-right: 10px;
        height: 100px;
    }
    .panel-footer {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
}

.sticky {
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: 0;
    z-index: 100;
    border-top: 0;
    max-width: 1148px;
}

.modal-lg {
    max-width: 1100px;
    width: 100%;
}

#category-table {

}

.profile-circle {
    width: 40px;
    height: 40px;
    display: block;
    content: attr(data-letter);
    font-size: 30px;
    padding: 9px;
    text-align: center;
    border-radius: 50%;
}

.k-event {
    min-height: 70px;
    padding-left: 60px !important;
    .profile-circle {
        margin-left: -50px;
        position: absolute;
    }
}

.wrap-table td {
    word-break: break-all;
}
@print-gutter: 1px;
@media print {
    div[class^="col-"],
    div[class*=" col-"] ,
    label[class^="col-"],
    label[class*=" col-"]
    {
        margin-left: @print-gutter !important;
        margin-right: @print-gutter !important;;
        padding-right: @print-gutter !important;;
        padding-left: @print-gutter !important;;
    }
    .row{
        margin-left: -@print-gutter;
        margin-right: -@print-gutter;
    }
}
