.select2-container {    
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle; 
    width: 100% !important;
}
.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 40px;
    user-select: none;
    -webkit-user-select: none; }
.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
.select2-container .select2-selection--single .select2-selection__clear {
    position: relative; }
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 40px;
    float: left;
    padding-bottom: 3px;
    width: 100%;
    user-select: none;
    -webkit-user-select: none; 
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap; }
.select2-container .select2-search--inline {
    width: auto;
    float: left; }
.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0; }
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none; }

.select2-dropdown {
    background-color: white;
    border: 1px solid @app-desing-border-color;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1062; }

.select2-results {
    display: block; }

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0; }

.select2-results__option {
    padding: 5px 20px;
    line-height: 30px;
    user-select: none;
    -webkit-user-select: none; 
}
.select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
    left: 0; }

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.select2-search--dropdown {
    display: block;
    padding: 10px; 
}
.select2-search--dropdown .select2-search__field {
    padding: 9px;
    width: 100%;
    box-sizing: border-box; 
    .border-radius();
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none; }
.select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0); }

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important; }

.select2-container--default .select2-selection--single {
    background-color: @app-desing-base-element-bg;
    border: 1px solid @app-desing-border-color;
    border-radius: 4px; 
}
.select2-container--default .select2-selection--single:hover{
    background-color: @app-desing-base-element-bg-hover;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 38px; 
    font-weight: 600;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default; }
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid @app-desing-border-color;
    border-radius: 5px;
    cursor: text; }
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none; }
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: @app-desing-base-element-bg-active;    
    color: @app-desing-base-element-bg-active-color;
    line-height: 30px;
    border-radius: 3px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 4px;
    padding: 0px 10px; 
    width: auto;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: @app-desing-base-element-bg-active-color;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    float: right;
    margin-left: 10px; }
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: darken(@app-desing-base-element-bg-active-color,10%); }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid darken(@app-desing-border-color, 3%);
    outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid @app-desing-border-color; }

.select2-container--default .select2-search--inline .select2-search__field {
    line-height: 30px;
    margin-left: 5px;
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
    padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: @app-desing-base-element-bg-active; 
    color: @app-desing-base-element-bg-active-color; 
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected=false] {   
    background-color: @app-desing-base-element-bg-hover;
    color: @app-layout-base-font-color;
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px; }