.app{
    
    .progress{
        float: left;
        width: 100%;
        height: 20px;
        margin-bottom: 0px;
        
        .progress-bar{
            line-height: 20px;
            font-size: 11px;
            font-weight: 600;            
        }
        
        &.progress-sm{
            height: 4px;
            margin: 8px 0px;
            
            .progress-bar{
                line-height: 4px;
                height: 4px;
            }
        }
        &.progress-md{
            height: 10px;
            margin: 5px 0px;
            
            .progress-bar{
                line-height: 10px;
                height: 10px;
            }
        }
        &.progress-lg{
            height: 30px;
            margin: 5px 0px;
            
            .progress-bar{
                line-height: 30px;
                height: 30px;
            }
        }
    }
    
}