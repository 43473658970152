.app{
    .list-group{
        float: left;
        width: 100%;
        
        .list-group-item{
            background: @app-desing-base-element-bg;
            border-color: @app-desing-border-color;
            .transition(background @app-default-transition-speed linear);
            line-height: @app-layout-base-line-height;
            
            span[class^='icon'],.fa{
                float: left;
                font-size: 20px;
                margin-right: 10px;
                line-height: 20px;
            }
            .fa{
                font-size: 14px;                
            }
            
            &.active, &.active:hover, &.active:focus{
                background: @app-desing-base-element-bg-active;
                color: @app-desing-base-element-bg-active-color;
                font-weight: 600;
                z-index: 0;
            }
            
            &.disabled, &.disabled:hover, &.disabled:focus{
                background: @app-desing-base-element-bg-hover;
            }
            &.list-group-item-highlighted{
                background: darken(@app-desing-base-element-bg,2%);
            }
        }
        
        .list-group-title{
            font-weight: 700;
            font-size: 11px;
            text-transform: uppercase;
            line-height: 20px;
            margin-top: 15px;
            margin-bottom: 5px;
            color: darken(@app-layout-base-font-color,10%);
            padding: 5px 15px;
                
            &:first-child{
                margin-top: 0px;
            }
        }
        
        a,button{
            &.list-group-item{
                &:hover,&:focus{
                    background: @app-desing-base-element-bg-hover;
                }
            }
        }        
        
        &.list-group-noborder{
            .list-group-item{
                border: 0px;                
                
                &.active, &.active:hover, &.active:focus{
                    background: @app-desing-base-element-bg-hover;
                    color: @app-layout-base-font-color;                    
                }
                
                &.disabled, &.disabled:hover, &.disabled:focus{
                    background: @app-desing-base-element-bg;
                }
            }
        }
        
        &.list-group-condensed{
            .list-group-item{
                border: 0px;
                padding: 8px 0px;
                background: transparent;
                color: @app-desing-base-element-link;
                .transition(color @app-default-transition-speed linear);
                    
                &.active, &.active:hover, &.active:focus{
                    background: transparent;
                    color: @app-desing-base-element-link-active;                    
                    //font-weight: 400;
                }
            }
            
            a,button{
                &.list-group-item{
                    &:hover,&:focus{
                        background: transparent;
                        color: @app-desing-base-element-link-hover;
                    }
                }
            }
            
            .list-group-title{
                padding: 0px;
                
                &:first-child{
                    margin-top: 10px;
                }
            }
        }
        
        &.list-group-inline{
            .list-group-item{                
                .border-radius(0px);
                
                &:not(:last-child){
                    border-right: 0px;
                }
            }
            
            &.list-group-adapt-top{
                .list-group-item{
                    border-top: 0px;
                }
            }
            &.list-group-adapt-bottom{
                .list-group-item{
                    border-bottom: 0px;
                }
            }
            &.list-group-adapt-side{
                .list-group-item{
                    &:first-child{
                        border-left: 0px;
                    }
                    &:last-child{
                        border-right: 0px;
                    }
                }
            }
        }
        
        &.list-group-adapt{
            .list-group-item{
                float: left;
                width: 100%;
                border-left: 0px;
                border-right: 0px;
                .border-radius(0px);
                padding: 15px;
                
                &.active, &.active:hover, &.active:focus{
                    background: @app-desing-base-element-bg-hover;
                    color: @app-layout-base-font-color;                    
                    font-weight: 400;
                }
                
                &.disabled, &.disabled:hover, &.disabled:focus{
                    background: @app-desing-base-element-bg;
                }
                
                &:first-child{
                    border-top: 0px;
                }
                &:last-child{
                    border-bottom: 0px;
                }
            }
        }
    }
    
    /* block support */
    .block{
        
        .app-heading + .list-group{
            margin-top: -5px;
        }
        
        > .list-group{
            margin-bottom: 0px;
            
            .list-group-item{
                .border-radius(0px);
                border-left: 0px;
                border-right: 0px;                
            }
            
            &:last-child{
                .list-group-item{
                    &:last-child{
                        border-bottom: 0px;
                        .border-radius(0px 0px @app-desing-border-radius @app-desing-border-radius);
                    }
                }
            }
            
            &.list-group-inline{
                .list-group-item{                    
                    border-right: 1px solid @app-desing-border-color;                                        
                    .border-radius(0px);
                }
                
                &:last-child{
                    .list-group-item{
                        &:first-child{
                            .border-radius(0px 0px 0px @app-desing-border-radius);
                        }
                        &:last-child{
                            .border-radius(0px 0px @app-desing-border-radius 0px);
                        }
                    }
                }
            }
        }
    }
    /* end block support */
    
    /* panel support */
    .panel{
        > .list-group{
            &.list-group-inline{
                .list-group-item{
                    border-top: 0px;
                    border-bottom: 0px;
                    border-right: 1px solid @app-desing-border-color;                                        
                    .border-radius(0px);
                }
                
                &:last-child{
                    .list-group-item{
                        &:first-child{
                            .border-radius(0px 0px 0px @app-desing-border-radius);
                        }
                        &:last-child{
                            .border-radius(0px 0px @app-desing-border-radius 0px);
                        }
                    }
                }
            }
        }
    }
    /* end panel support */
    
    /* news and info listing */
    .listing{
        float: left;
        width: 100%;
        list-style: none;
        padding: 0px;
        margin-bottom: 20px;
        
        > .listing-item{
            position: relative;
            float: left;
            width: 100%;
            line-height: 20px;
            padding: 5px 0px;
            
            a{font-weight: 600;}                        
            
            .fa{font-size: 14px;}
            
            // grid mode changes
            > .row{
                margin: 0px;
                
                > div[class^='col-']{
                    padding: 0px 15px 0px 0px;
                    
                    > img{
                        margin-top: 6px;
                    }
                    
                    &:last-child{
                        padding-right: 0px;
                    }
                }
            }
            // end grid mode changes
            
            &.listing-item-with-icon{            
                padding-left: 30px;

                .listing-item-icon{
                    position: absolute;
                    left: 0px;
                    top: 5px;
                    width: 20px;
                    text-align: center;
                    font-size: 20px;
                    line-height: 20px;                                        
                }
            }
        }
        
        &.dir-right{
            > .listing-item{
                &.listing-item-with-icon{            
                    padding-right: 30px;
                    padding-left: 0px;

                    .listing-item-icon{
                        position: absolute;
                        right: 0px;
                        left: auto;
                        top: 5px;
                    }
                }
            }
        }
        
        &.listing-separated{
            > .listing-item{
                border-bottom: 1px solid @app-desing-border-color;
                padding-bottom: 10px;
                
                &:last-child{
                    border-bottom: 0px;
                    padding-bottom: 0px;
                }
            }
        }
    }
    /* end news and info listing */
}

@media (max-width: @screen-md) {
    .app{
        .listing{        
            > .listing-item{                
                > .row{
                    > div[class^='col-']{
                        padding: 0px;
                    }
                }
            }
        }
    }
}