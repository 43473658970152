.margin-0{margin: 0px !important;}
.margin-top-0{margin-top: 0px !important;}
.margin-top-5{margin-top: 5px !important;}
.margin-top-10{margin-top: 10px !important;}
.margin-top-15{margin-top: 15px !important;}
.margin-top-20{margin-top: 20px !important;}
.margin-top-30{margin-top: 30px !important;}
.margin-top-40{margin-top: 40px !important;}
.margin-top-50{margin-top: 50px !important;}
.margin-top-100{margin-top: 100px !important;}
.margin-top-minus-one{margin-top: -1px !important;}

.margin-bottom-0{margin-bottom: 0px !important;}
.margin-bottom-5{margin-bottom: 5px !important;}
.margin-bottom-10{margin-bottom: 10px !important;}
.margin-bottom-15{margin-bottom: 15px !important;}
.margin-bottom-20{margin-bottom: 20px !important;}
.margin-bottom-30{margin-bottom: 30px !important;}
.margin-bottom-40{margin-bottom: 40px !important;}
.margin-bottom-50{margin-bottom: 50px !important;}
.margin-bottom-100{margin-bottom: 100px !important;}

.margin-left-0{margin-left: 0px !important;}
.margin-left-5{margin-left: 5px !important;}
.margin-left-10{margin-left: 10px !important;}
.margin-left-15{margin-left: 15px !important;}
.margin-left-20{margin-left: 20px !important;}
.margin-left-30{margin-left: 30px !important;}
.margin-left-40{margin-left: 40px !important;}
.margin-left-50{margin-left: 50px !important;}

.margin-right-0{margin-right: 0px !important;}
.margin-right-5{margin-right: 5px !important;}
.margin-right-10{margin-right: 10px !important;}
.margin-right-15{margin-right: 15px !important;}
.margin-right-20{margin-right: 20px !important;}
.margin-right-30{margin-right: 30px !important;}
.margin-right-40{margin-right: 40px !important;}
.margin-right-50{margin-right: 50px !important;}

.padding-0{padding: 0px !important;}
.padding-5{padding: 5px !important;}
.padding-10{padding: 10px !important;}
.padding-15{padding: 15px !important;}
.padding-20{padding: 20px !important;}
.padding-30{padding: 30px !important;}
.padding-40{padding: 40px !important;}
.padding-50{padding: 50px !important;}

.padding-left-0{padding-left: 0px !important;}
.padding-left-5{padding-left: 5px !important;}
.padding-left-10{padding-left: 10px !important;}
.padding-left-15{padding-left: 15px !important;}
.padding-left-20{padding-left: 20px !important;}
.padding-left-30{padding-left: 30px !important;}
.padding-left-40{padding-left: 40px !important;}
.padding-left-50{padding-left: 50px !important;}

.padding-right-0{padding-right: 0px !important;}
.padding-right-5{padding-right: 5px !important;}
.padding-right-10{padding-right: 10px !important;}
.padding-right-15{padding-right: 15px !important;}
.padding-right-20{padding-right: 20px !important;}
.padding-right-30{padding-right: 30px !important;}
.padding-right-40{padding-right: 40px !important;}
.padding-right-50{padding-right: 50px !important;}

.padding-top-0{padding-top: 0px !important;}
.padding-top-5{padding-top: 5px !important;}
.padding-top-10{padding-top: 10px !important;}
.padding-top-15{padding-top: 15px !important;}
.padding-top-20{padding-top: 20px !important;}
.padding-top-30{padding-top: 30px !important;}
.padding-top-40{padding-top: 40px !important;}
.padding-top-50{padding-top: 50px !important;}

.padding-bottom-0{padding-bottom: 0px !important;}
.padding-bottom-5{padding-bottom: 5px !important;}
.padding-bottom-10{padding-bottom: 10px !important;}
.padding-bottom-15{padding-bottom: 15px !important;}
.padding-bottom-20{padding-bottom: 20px !important;}
.padding-bottom-30{padding-bottom: 30px !important;}
.padding-bottom-40{padding-bottom: 40px !important;}
.padding-bottom-50{padding-bottom: 50px !important;}

.border-top-0{border-top: 0px !important;}
.border-bottom-0{border-bottom: 0px !important;}
.border-left-0{border-left: 0px !important;}
.border-right-0{border-right: 0px !important;}

.text-default{color: @app-layout-base-font-color !important;}
.text-muted{color: @app-layout-base-font-color-secondary !important;}
.text-primary{color: @app-design-primary-bg !important;}
.text-info{color: @app-design-info-bg !important;}
.text-success{color: @app-design-success-bg !important;}
.text-warning{color: @app-design-warning-bg !important;}
.text-danger{color: @app-design-danger-bg !important;}

.text-sm{font-size: @app-layout-base-font-size - 2;}
.text-rg{font-size: @app-layout-base-font-size;}
.text-lg{font-size: @app-layout-base-font-size + 2;}
.text-xlg{font-size: @app-layout-base-font-size + 4;}

.text-thin{font-weight: 300 !important;}
.text-regular{font-weight: @app-layout-base-font-weight !important;}
.text-bold{font-weight: 600 !important;}
.text-bolder{font-weight: 700 !important;}

.text-uppercase{text-transform: uppercase !important;}
.text-lowercase{text-transform: lowercase !important;}
.text-capitalize{text-transform: capitalize !important;}
.text-italic{font-style: italic !important;}

.wide{width: 100%;}
.visible-mobile{display: none;}

.text-right{
    text-align: right !important;
}
.text-left{
    text-align: left !important;
}

.animate-infinite{
    -vendor-animation-duration: 1s;
    -vendor-animation-delay: 1s;
    -vendor-animation-iteration-count: infinite;
}

// aditional text-colors
.app-color-example{
    float: left;
    width: 100%;
    padding: 5px;
    line-height: 20px;
    border: 1px dashed @app-desing-border-color;
    .border-radius();
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}
.ct-build(@name, @color){
    .@{name}{        
        color: @color !important;
    }
}
.ct-build(text-bloody-600,#6C0000);
.ct-build(text-bloody-500,lighten(#6C0000,15%));
.ct-build(text-bloody-400,lighten(#6C0000,30%));
.ct-build(text-bloody-300,lighten(#6C0000,45%));
.ct-build(text-bloody-200,lighten(#6C0000,55%));
.ct-build(text-bloody-100,lighten(#6C0000,65%));

.ct-build(text-orange-600,#D37001);
.ct-build(text-orange-500,lighten(#D37001,10%));
.ct-build(text-orange-400,lighten(#D37001,20%));
.ct-build(text-orange-300,lighten(#D37001,30%));
.ct-build(text-orange-200,lighten(#D37001,35%));
.ct-build(text-orange-100,lighten(#D37001,40%));

.ct-build(text-yellow-600,#DFDA00);
.ct-build(text-yellow-500,lighten(#DFDA00,10%));
.ct-build(text-yellow-400,lighten(#DFDA00,15%));
.ct-build(text-yellow-300,lighten(#DFDA00,20%));
.ct-build(text-yellow-200,lighten(#DFDA00,25%));
.ct-build(text-yellow-100,lighten(#DFDA00,30%));

.ct-build(text-lime-600,#466C00);
.ct-build(text-lime-500,lighten(#466C00,10%));
.ct-build(text-lime-400,lighten(#466C00,15%));
.ct-build(text-lime-300,lighten(#466C00,20%));
.ct-build(text-lime-200,lighten(#466C00,25%));
.ct-build(text-lime-100,lighten(#466C00,30%));

.ct-build(text-green-600,#006C00);
.ct-build(text-green-500,lighten(#006C00,10%));
.ct-build(text-green-400,lighten(#006C00,15%));
.ct-build(text-green-300,lighten(#006C00,30%));
.ct-build(text-green-200,lighten(#006C00,40%));
.ct-build(text-green-100,lighten(#006C00,50%));

.ct-build(text-sea-600,#00D595);
.ct-build(text-sea-500,lighten(#00D595,10%));
.ct-build(text-sea-400,lighten(#00D595,15%));
.ct-build(text-sea-300,lighten(#00D595,20%));
.ct-build(text-sea-200,lighten(#00D595,25%));
.ct-build(text-sea-100,lighten(#00D595,30%));

.ct-build(text-teal-600,#00696C);
.ct-build(text-teal-500,lighten(#00696C,15%));
.ct-build(text-teal-400,lighten(#00696C,25%));
.ct-build(text-teal-300,lighten(#00696C,35%));
.ct-build(text-teal-200,lighten(#00696C,45%));
.ct-build(text-teal-100,lighten(#00696C,50%));

.ct-build(text-blue-600,#00416C);
.ct-build(text-blue-500,lighten(#00416C,15%));
.ct-build(text-blue-400,lighten(#00416C,25%));
.ct-build(text-blue-300,lighten(#00416C,35%));
.ct-build(text-blue-200,lighten(#00416C,45%));
.ct-build(text-blue-100,lighten(#00416C,50%));

.ct-build(text-darkblue-600,#00336C);
.ct-build(text-darkblue-500,lighten(#00336C,10%));
.ct-build(text-darkblue-400,lighten(#00336C,20%));
.ct-build(text-darkblue-300,lighten(#00336C,30%));
.ct-build(text-darkblue-200,lighten(#00336C,40%));
.ct-build(text-darkblue-100,lighten(#00336C,45%));

.ct-build(text-navyblue-600,#001C6C);
.ct-build(text-navyblue-500,lighten(#001C6C,10%));
.ct-build(text-navyblue-400,lighten(#001C6C,20%));
.ct-build(text-navyblue-300,lighten(#001C6C,35%));
.ct-build(text-navyblue-200,lighten(#001C6C,45%));
.ct-build(text-navyblue-100,lighten(#001C6C,50%));

.ct-build(text-indigo-600,#1F016B);
.ct-build(text-indigo-500,lighten(#1F016B,10%));
.ct-build(text-indigo-400,lighten(#1F016B,22%));
.ct-build(text-indigo-300,lighten(#1F016B,35%));
.ct-build(text-indigo-200,lighten(#1F016B,45%));
.ct-build(text-indigo-100,lighten(#1F016B,55%));

.ct-build(text-purple-600,#41006C);
.ct-build(text-purple-500,lighten(#41006C,10%));
.ct-build(text-purple-400,lighten(#41006C,22%));
.ct-build(text-purple-300,lighten(#41006C,35%));
.ct-build(text-purple-200,lighten(#41006C,45%));
.ct-build(text-purple-100,lighten(#41006C,55%));

.ct-build(text-magenta-600,#6C0069);
.ct-build(text-magenta-500,lighten(#6C0069,10%));
.ct-build(text-magenta-400,lighten(#6C0069,22%));
.ct-build(text-magenta-300,lighten(#6C0069,35%));
.ct-build(text-magenta-200,lighten(#6C0069,45%));
.ct-build(text-magenta-100,lighten(#6C0069,55%));

.ct-build(text-pink-600,#6C004B);
.ct-build(text-pink-500,lighten(#6C004B,10%));
.ct-build(text-pink-400,lighten(#6C004B,22%));
.ct-build(text-pink-300,lighten(#6C004B,35%));
.ct-build(text-pink-200,lighten(#6C004B,45%));
.ct-build(text-pink-100,lighten(#6C004B,55%));

.ct-build(text-maroon-600,#6C0023);
.ct-build(text-maroon-500,lighten(#6C0023,10%));
.ct-build(text-maroon-400,lighten(#6C0023,22%));
.ct-build(text-maroon-300,lighten(#6C0023,35%));
.ct-build(text-maroon-200,lighten(#6C0023,45%));
.ct-build(text-maroon-100,lighten(#6C0023,55%));
// end

@media (max-width: @screen-md) {
    .visible-mobile{display: block;}
    .hidden-mobile{display: none;}
    .float-left-mobile{float: left !important;}
    .float-right-mobile{float: right !important;}
    .clear-mobile{clear: both;}
    .text-left-mobile{text-align: left !important;}
    .text-right-mobile{text-align: right !important;}
}