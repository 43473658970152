/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.37
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
@bs-datetimepicker-timepicker-font-size: @app-layout-base-font-size;
@bs-datetimepicker-active-bg: @app-design-primary-bg;
@bs-datetimepicker-active-color: @app-design-primary-color;
@bs-datetimepicker-border-radius: 3px;
@bs-datetimepicker-btn-hover-bg: @app-desing-base-element-bg-hover;
@bs-datetimepicker-disabled-color: lighten(@app-layout-base-font-color-secondary,5%);
@bs-datetimepicker-alternate-color: @app-layout-base-font-color-secondary;
@bs-datetimepicker-secondary-border-color: @app-desing-border-color;
@bs-datetimepicker-secondary-border-color-rgba: @app-desing-border-color;
@bs-datetimepicker-primary-border-color: white;
@bs-datetimepicker-text-shadow: none;

.bootstrap-datetimepicker-widget {
    list-style: none;
    z-index: 999;

    &.dropdown-menu {
        margin: 1px 0px;
        padding: 4px;
        width: 300px;

        
        &.timepicker-sbs {
            @media (min-width: 768px) {
                width: 38em;
            }

            @media (min-width: 992px) {
                width: 38em;
            }

            @media (min-width: 1200px) {
                width: 38em;
            }
        }               
    }

    .list-unstyled {
        margin: 0;
    }

    a[data-action] {
        padding: 6px 0;
    }

    a[data-action]:active {
        box-shadow: none;
    }

    .timepicker-hour, .timepicker-minute, .timepicker-second {
        width: 54px;
        font-weight: bold;
        font-size: @bs-datetimepicker-timepicker-font-size;
        margin: 0;
    }

    button[data-action] {
        padding: 6px;
    }

    .btn[data-action="incrementHours"]::after {
        .sr-only();
        content: "Increment Hours";
    }

    .btn[data-action="incrementMinutes"]::after {
        .sr-only();
        content: "Increment Minutes";
    }

    .btn[data-action="decrementHours"]::after {
        .sr-only();
        content: "Decrement Hours";
    }

    .btn[data-action="decrementMinutes"]::after {
        .sr-only();
        content: "Decrement Minutes";
    }

    .btn[data-action="showHours"]::after {
        .sr-only();
        content: "Show Hours";
    }

    .btn[data-action="showMinutes"]::after {
        .sr-only();
        content: "Show Minutes";
    }

    .btn[data-action="togglePeriod"]::after {
        .sr-only();
        content: "Toggle AM/PM";
    }

    .btn[data-action="clear"]::after {
        .sr-only();
        content: "Clear the picker";
    }

    .btn[data-action="today"]::after {
        .sr-only();
        content: "Set the date to today";
    }

    .picker-switch {
        text-align: center;

        &::after {
            .sr-only();
            content: "Toggle Date and Time Screens";
        }

        td {
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;
            line-height: inherit;

            span {
                line-height: 2.5;
                height: 2.5em;
                width: 100%;
            }
        }
    }

    table {
        width: 100%;
        margin: 0;


        & td,
        & th {
            text-align: center;
            border-radius: 3px;
            padding: 8px !important;
        }

        & th {
            height: 20px;
            line-height: 20px;
            width: 20px;

            &.picker-switch {
                width: 145px;
            }

            &.disabled,
            &.disabled:hover {
                background: none;
                color: @bs-datetimepicker-disabled-color;
                cursor: not-allowed;
            }

            &.prev::after {
                .sr-only();
                content: "Previous Month";
            }

            &.next::after {
                .sr-only();
                content: "Next Month";
            }
        }

        & thead tr:first-child th {
            cursor: pointer;

            &:hover {
                background: @bs-datetimepicker-btn-hover-bg;
            }
        }

        & td {
            height: 54px;
            line-height: 54px;
            width: 54px;

            &.cw {
                font-size: .8em;
                height: 20px;
                line-height: 20px;
                color: @bs-datetimepicker-alternate-color;
            }

            &.day {
                height: 20px;
                line-height: 20px;
                width: 20px;
            }

            &.day:hover,
            &.hour:hover,
            &.minute:hover,
            &.second:hover {
                background: @bs-datetimepicker-btn-hover-bg;
                cursor: pointer;
            }

            &.old,
            &.new {
                color: @bs-datetimepicker-alternate-color;
            }

            &.today {
                position: relative;

                &:before {
                    content: '';
                    display: inline-block;
                    border: solid transparent;
                    border-width: 0 0 7px 7px;
                    border-bottom-color: @bs-datetimepicker-active-bg;
                    border-top-color: @bs-datetimepicker-secondary-border-color-rgba;
                    position: absolute;
                    bottom: 4px;
                    right: 4px;
                }
            }

            &.active,
            &.active:hover {
                background-color: @bs-datetimepicker-active-bg;
                color: @bs-datetimepicker-active-color;
                text-shadow: @bs-datetimepicker-text-shadow;
            }

            &.active.today:before {
                border-bottom-color: #fff;
            }

            &.disabled,
            &.disabled:hover {
                background: none;
                color: @bs-datetimepicker-disabled-color;
                cursor: not-allowed;
            }

            span {
                display: inline-block;
                width: 54px;
                height: 54px;
                line-height: 54px;
                margin: 2px 1.5px;
                cursor: pointer;
                border-radius: @bs-datetimepicker-border-radius;
                font-size: 20px;
                color: @app-layout-base-font-color;

                &:hover {
                    background: @bs-datetimepicker-btn-hover-bg;
                }

                &.active {
                    background-color: @bs-datetimepicker-active-bg;
                    color: @bs-datetimepicker-active-color;
                    text-shadow: @bs-datetimepicker-text-shadow;
                }

                &.old {
                    color: @bs-datetimepicker-alternate-color;
                }

                &.disabled,
                &.disabled:hover {
                    background: none;
                    color: @bs-datetimepicker-disabled-color;
                    cursor: not-allowed;
                }
            }
        }
    }

    &.usetwentyfour {
        td.hour {
            height: 27px;
            line-height: 27px;
        }
    }
	
	&.wider {
		width: 21em;
	}

	& .datepicker-decades .decade {
        line-height: 1.8em !important;
    }
}

.datepicker-months, .datepicker-decades, .datepicker-years{
    table{
        tr{
            td{
                span{
                    
                    font-size: @bs-datetimepicker-timepicker-font-size;
                }
            }
        }        
    }
}
.datepicker-decades table tr td span{
    padding: 5px 0px;
}

.input-group.date {
    & .input-group-addon {
        cursor: pointer;
    }
}
