@app-login-box-bg: @app-desing-base-element-bg;
@app-login-box-width: 360px;
@app-login-box-color: @app-layout-base-font-color-heading;
@app-login-box-color-secondary: @app-layout-base-font-color-secondary;
@app-login-box-user-border: @app-desing-border-color;

.app{
    
    .app-login-box{
        width: @app-login-box-width;
        margin: 50px auto;                
        background: @app-login-box-bg;
        .border-radius();
        .clearfix();
        .box-shadow(0px 4px 20px 5px rgba(0,0,0,0.05));
        
        .app-login-box-user{
            width: 100%;
            float: left;
            text-align: center;
            margin-bottom: 20px;
            padding: 30px 30px 0px;
            
            img{
                width: 100px;
                border: 1px solid @app-login-box-user-border;
                padding: 5px;
                .border-radius(50%);                
            }
        }
        .app-login-box-title{
            float: left;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            padding: 0px 30px;            
            
            .title{
                font-size: 18px;
                font-weight: 400;                
                color: @app-login-box-color;                
                margin-bottom: 5px;
            }
            .subtitle{
                font-size: 14px;
                color: @app-login-box-color-secondary;
            }
        }
        .app-login-box-container{
            float: left;
            width: 100%;
            padding: 0px 30px 30px;
            
            > .title{
                float: left;
                width: 100%;
                font-size: 14px;
                font-weight: 400;                
                color: @app-login-box-color-secondary;
                margin-bottom: 20px;
                text-align: center;
            }
        }
        
        .app-login-box-or{
            float: left;
            width: 100%;
            padding: 10px 0px 40px;

            .or{                
                width: 40px;
                height: 40px;
                margin: 0px auto;
                position: relative;
                line-height: 40px;
                text-align: center;
                font-weight: 700;
                color: @app-login-box-color-secondary;                
                
                &:after{
                    content: " ";
                    width: 40px;
                    height: 40px;
                    border: 1px solid @app-login-box-user-border;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    .rotate(45deg);
                }                
            }
        }
        
        .app-login-box-footer{
            float: left;
            width: 100%;
            background: darken(@app-desing-base-element-bg,2%);
            padding: 15px 30px;
            .border-radiuses(0px,0px,@app-desing-border-radius,@app-desing-border-radius);
            text-align: center;
        }
    }
    
    &.app-fh{
        height: 100%;
        
        .app-container{
            height: 100%;
        }
    }
    
    
    .app-lock{
        width: @app-login-box-width;
        position: absolute;
        left: 50%;
        margin-left: -@app-login-box-width/2;
        top: 50%;
        margin-top: -150px;
        
        .app-lock-user{
            width: 100%;
            height: 100px;
            float: left;
            text-align: center;
            cursor: pointer;                        
            
            img{
                width: 100px;
                border: 1px solid @app-login-box-user-border;
                padding: 5px;                
                .border-radius(50%);
                .transition(all @app-default-transition-speed linear);
                .box-shadow(0px 4px 20px 5px rgba(0,0,0,0.0));
            }
            
            &:hover{
                img{
                    .box-shadow(0px 4px 20px 5px rgba(0,0,0,0.05));
                }
            }            
        }
        
        .app-lock-form{
            position: relative;
            float: left;
            width: 100%;
            margin-top: 30px;
            background: @app-login-box-bg;
            border: 1px solid @app-login-box-user-border;
            display: none;
            .border-radius();
            padding: 15px;
            .box-shadow(0px 4px 20px 5px rgba(0,0,0,0.05));
            .transition(all 500ms linear);
            
            .form-control{
                text-align: center;
            }
            
            &:after, &:before {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-bottom-color: @app-design-block-bg;
                    border-width: 10px;
                    margin-left: -10px;
            }
            &:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-bottom-color: @app-desing-border-color;
                    border-width: 11px;
                    margin-left: -11px;
            }
        }
        
        &.app-lock-open{            
            .app-lock-user{
                img{
                    .box-shadow(0px 4px 20px 5px rgba(0,0,0,0.05));
                }
            }            
        }
    }
}

@media (max-width: @screen-md) {
    .app{
    
    }
}