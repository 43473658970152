.app{
    
    .table{
        tr{
            td,th{
                line-height: 20px;
                padding: 10px 15px;
                vertical-align: middle;
            }
        }
        > thead{
            > tr > th{
                font-size: 11px;                
                text-transform: uppercase;
                font-weight: 700;
                color: @app-layout-base-font-color-heading;
                border-bottom: 1px solid @app-desing-border-color;
            }
        }
        > tbody{
            > tr{ 
                > td{                    
                    border-bottom: 1px solid @app-desing-border-color;
                }
                
                &:last-child{
                    > td{
                        border-bottom: 0px;
                    }
                }
            }
        }
        
        &.table-striped{
            > tbody{
                > tr:nth-of-type(odd){
                    background: @app-desing-base-element-bg-hover;
                }
            } 
        }
        
        &.table-bordered{
            border: 1px solid @app-desing-border-color;
            
            > tbody{
                > tr{
                    > td{
                        border: 0px;
                        border-bottom: 1px solid @app-desing-border-color;
                        border-right: 1px solid @app-desing-border-color;
                        
                        &:last-child{
                            border-right: 0px;
                        }
                    }
                    &:last-child{
                        > td{
                            border-bottom: 0px;
                        }
                    }
                }
            }            
        }
        &.table-hover{
            > tbody{
                > tr{ 
                    &:hover{
                        > td{
                            background: @app-desing-base-element-bg-hover;
                        }
                    }
                }
            }
        }
        &.table-head-light{
            > thead{
                > tr > th{
                    color: @app-layout-base-font-color-secondary;                    
                }
            }
        }
        &.table-head-custom{
            > thead{
                > tr > th{
                    background: @app-desing-base-element-bg-active;
                    color: @app-desing-base-element-bg-active-color;
                }
            }
        }
        &.table-noborder{
            > thead > tr > th,
            > tbody > tr > td{
                border: 0px;
            }
        }
        
        &.table-clean-paddings{
            > thead > tr > th:first-child,
            > tbody > tr > td:first-child{
                padding-left: 0px;
            }
            > thead > tr > th:last-child,
            > tbody > tr > td:last-child{
                padding-right: 0px;
            }
        }
    }
    
    .block{
        > .table{
            &.table-bordered{
                border-left: 0px;
                border-right: 0px;                
                
                > thead > tr > th,
                > tbody > tr > td{
                    border-left: 0px;                    
                }
                > thead > tr > th:last-child,
                > tbody > tr > td:last-child{
                    border-right: 0px;
                }
            }
            &:last-child{
                margin-bottom: 0px;
                border-bottom: 0px;
            }                        
        }
    }
    
    .table{
        .contact{            
            &.contact-lg, &.contact-xlg, &.contact-single{
                margin-bottom: 0px;
            }
        }
    }
    
}

@media (max-width: @screen-md) {
    .table-responsive{
        border: 0px;
    }
}